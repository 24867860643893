/* .uploadArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  min-height: 10em;
} */

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  border: 2px dashed #007bff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
}

.file-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #007bff;
  font-size: 1.2rem;
  pointer-events: none;
}

.dragging {
  background-color: #e0e0e0;
}
