.fab {
  border-radius: 45% !important;
  bottom: 6em;
  /* font-size: 24px !important; */
  height: 5em;
  width: 5em;
  padding: 25px;
  position: fixed;
  right: 25px;
  z-index: 10;
}

.fab-danger {
  bottom: 20px;
  padding: 10px;
  position: fixed;
  left: 25px;
}
