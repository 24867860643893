input {
  border-radius: 20px;
}

.opacity {
  opacity: 0.5;
}

.cursorDisable {
  cursor: not-allowed !important;
}

.fab-button {
  bottom: 6em;
  /* font-size: 24px !important; */
  height: 6rem;
  width: 8rem;
  padding: 25px;
  position: fixed;
  right: 25px;
  z-index: 10;
}
